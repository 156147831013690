<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="d_f">
      <div class="flex_1">

        <!--退款申请记录-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>退款申请记录</div>
          </div>

          <el-table
            ref="multipleTable"
            :data="list"
            stripe
            :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column label="退款单号" align="center">
              <template #default="scope">
                <span class="cur_p t_num" @click="goDetail(scope.row)">{{scope.row.refundNo}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="退款方式" align="center">
              <template #default="scope"><span>原路退回</span></template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center" show-overflow-tooltip>
              <template #default="scope">
                <span>{{reArr[scope.row.status]}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="creatorTime" label="退款申请时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="refundAmount" label="退款金额" align="center" show-overflow-tooltip>
              <template #default="scope">
                <span>{{scope.row.refundAmount ? Number(scope.row.refundAmount/100).toFixed(2) : 0}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="serviceCharge" label="手续费" align="center" show-overflow-tooltip>
              <template #default="scope">
                <span>{{scope.row.serviceCharge ? Number(scope.row.serviceCharge/100).toFixed(2) : 0}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="trueRefundAmount" label="实际退款金额" align="center" show-overflow-tooltip>
              <template #default="scope">
                <span>{{scope.row.trueRefundAmount ? Number(scope.row.trueRefundAmount/100).toFixed(2) : 0}}</span>
              </template>
            </el-table-column>
            <!--<el-table-column prop="refundType" label="审核类型" align="center" show-overflow-tooltip>

            </el-table-column>
            <el-table-column prop="address" label="系统审核时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="address" label="营销审核时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="address" label="财务审核时间" align="center" show-overflow-tooltip></el-table-column>-->
          </el-table>
        </div>
      </div>
      <div class="td_r"></div>
    </div>
    <div class="td_rp p_f">
      <div class="tdr_title p_r t_a">
        <span>退款详情</span>
        <div class="p_a"></div>
      </div>
      <div class="ch_btn">
        <div @click="$router.go(-1)">返回上一级</div>
      </div>
    </div>
    <!--详情弹窗-->
    <el-dialog
      v-model="dialogVisible"
      width="85%"
      :show-close="false"
    >
      <template #title>
        <div class="uc_title d_f ali_c flex_1">
          <div class="uc_green"></div>
          <div>退款单详情</div>
          <div class="flex_1 t_r" @click="dialogVisible = false">
            <img class="close" src="../assets/images/bk_close.png" alt="">
          </div>
        </div>
      </template>
      <div class="td_pop_con t_l">

        <el-row>
          <el-col :span="8"><span>退款单号：{{chooseInfo.refundNo}}</span></el-col>
          <el-col :span="8"><span>退款方式：原路退回</span></el-col>
          <el-col :span="8"><span>状态：{{reArr[chooseInfo.status]}}</span></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><span>退款申请时间：{{chooseInfo.creatorTime}}</span></el-col>
          <el-col :span="8"><span>退款金额：{{chooseInfo.refundAmount ? Number(chooseInfo.refundAmount/100).toFixed(2) : 0}}</span></el-col>
          <el-col :span="8"><span>手续费：{{chooseInfo.serviceCharge ? Number(chooseInfo.serviceCharge/100).toFixed(2) : 0}}</span></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><span>实际退款金额：{{chooseInfo.trueRefundAmount ? Number(chooseInfo.trueRefundAmount/100).toFixed(2) : 0}}</span></el-col>
          <!--<el-col :span="8"><span>审核类型：{{}}</span></el-col>-->
          <!--<el-col :span="8"><span>系统审核时间：{{}}</span></el-col>-->
        </el-row>
        <el-row>
          <!--<el-col :span="8"><span>营销审核时间：{{}}</span></el-col>-->
          <!--<el-col :span="8"><span>财务审核时间：{{}}</span></el-col>-->
        </el-row>
        <el-row>
          <el-col :span="24"><span>审核意见：{{chooseInfo.missCause}}</span></el-col>
        </el-row>
      </div>
      <div class="table_title t_l">退款商品明细</div>

      <el-table
        ref="multipleTable"
        :data="popList"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="groupCode" label="商品编码" align="center">
          <template #default="scope">
            <span>{{scope.row.groupCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="commodityName" label="商品名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="sellPrice" label="渠道价" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span>{{scope.row.sellPrice ? Number(scope.row.sellPrice/100).toFixed(2) : 0}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="visitorName" label="游客姓名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="certificateNumber" label="证件号码" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="状态" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span>{{reArr[chooseInfo.status]}}</span>
          </template>
        </el-table-column>
        <!--<el-table-column prop="address" label="预计消费日期" align="center" show-overflow-tooltip></el-table-column>-->
        <el-table-column prop="beginDate" label="开始时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="endDate" label="结束时间" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "refundDetail",
    data() {
      return {
        list:[],
        popList:[],
        dialogVisible:false,
        reArr:['','','退票中','退票失败','退款中','退款失败',],
        chooseInfo:{},
      }
    },
    created(){
      this.queryOrderRefundList();
    },
    methods: {
      //跳转详情
      goDetail(row){
        this.chooseInfo = row;
        this.$api.queryOrderRefundDetail({orderRefundId:row.orderRefundId}).then(res => {
          this.dialogVisible = true;
          this.popList = res.data;
        })
      },
      queryOrderRefundList(){
        this.$api.queryOrderRefundList({orderNumber:this.$route.query.orderNumber}).then(res => {
          this.list = res.data;
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .el-table{
    margin-bottom: 82px;
  }
  .table_title{
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  .td_pop_con{
    line-height: 2.5;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 35px;
  }
  :v-deep .el-dialog__body{
    padding: 0 35px;
  }

</style>
